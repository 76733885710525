import React, { Component } from 'react'
import { BrowserRouter as Router, Navigate, withRouter } from 'react-router-dom'
import {
  // MDBNavbar,
  // MDBNavbarBrand,
  // MDBNavbarNav,
  // MDBNavbarToggler,
  // MDBCollapse,
  // MDBNavbarItem,
  MDBFooter,
  
  // MDBNavbarLink,
  // MDBTooltip,
  // MDBIcon,
  // MDBDropdownMenu,
  // MDBDropdown,
  // MDBDropdownToggle,
  // MDBAvatar,
  // MDBDropdownItem,
  // MDBDropdownLink,
} from 'mdb-react-ui-kit'

// import { ReactComponent as Logo } from './assets/logo.svg'
import Routes from './Routes'
import Logo from './assets/phfilogo.png'

const UserId = sessionStorage.getItem('userid')

let Photo = sessionStorage.getItem('photo')

class App extends Component {
//   constructor(props){
//     super(props)
    
// }
  state = {
    collapseID: '',
    userName: '',
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
      userName:
        sessionStorage.getItem('name') !== null ||
        sessionStorage.getItem('name') !== undefined
          ? sessionStorage.getItem('name')
          : '',
    }))

  closeCollapse = (collID) => () => {
    const { collapseID } = this.state
    window.scrollTo(0, 0)
    collapseID === collID && this.setState({ collapseID: '' })
  }
  componentDidUpdate() {
    // this.setState({ userName: name })
    //console.log(Photo)
  }
  // logout = () => {
  //   sessionStorage.clear()
  //   window.location.href = '/'
  // }
  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    )

    const { collapseID } = this.state

    return (
      <Router>
        <div className="flyout">
          
          {/* {collapseID && overlay} */}
          <main style={{ marginTop: '4rem' }}>
            <Routes />
          </main>
         
        </div>
      </Router>
    )
  }
}

export default App