import React from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
// import Signin from './pages/Signin'
import Login from './pages/Login'
import List from './pages/List'
import Card from './pages/Card'
import Idotp from './pages/Idotp'
import Verify from './pages/Verify'
import PNGCard from './pages/PNGCard'
import Register from './pages/Register'
class Routes extends React.Component {
  render() {
    return (
        <Switch>
        <Route  path="/" element={<Login/>} />
        <Route  path="/List" element={<List/>} />
        <Route  path="/Login" element={<Login/>} /> 
        <Route  path="/Card" element={<Card/>} />
        <Route  path="/CreateAbha" element={<Idotp/>} />
        <Route  path="/Verify" element={<Verify/>} />
        <Route  path="/CardDetails" element={<PNGCard/>} />
        <Route path="/Register" element={<Register/>} />        
        <Route
          render={function () {
            return <h1>Not Found</h1>
          }}
        />
      </Switch>
    )
  }
}

export default Routes