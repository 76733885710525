import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Logo from '../assets/clogo.png';
import Nhalogo from '../assets/NHA.png';
// import doctor from '../assets/Doctors.jpg';
import newdoctor from '../assets/newdoctor.png'
import ablogo from '../assets/abhalogo.png';
import  backdrop  from '../assets/backdrop.jpg';
import { useNavigate } from 'react-router-dom';
import OTPInput from "react-otp-input";
import "./Verify.css";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from 'buffer';
import JSEncrypt from 'jsencrypt';
import { useMediaQuery } from "react-responsive";
import Responsive from 'react-responsive';

import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBInput,
  MDBCardBody,
  MDBCard,
  MDBIcon,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBProgress,
  MDBProgressBar,
  MDBValidation,
  MDBValidationItem,
  MDBCheckbox,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBFooter,
  MDBRow,MDBCol,MDBAlert,
  MDBTypography
} from 'mdb-react-ui-kit';
import { eventNames } from 'process';
import './login.css'



const MobileNumberForm = (props) => {
  
  const [progress, setProgress] = useState(0);
  const [showNav, setShowNav] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState('');
  // const [aOtp, seAtOTP] = useState('');

  const [justifyActive, setJustifyActive] = useState('tab1');
  const [resendTimer, setResendTimer] = useState(60);
  const navigate = useNavigate();
  const [confirmation, setShowConfirmation] = useState(false);
  const [topRightModal, setTopRightModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [showAlert, setShowAlert] = useState(false)
  const [alertId, setAlertId] = useState('');
  
  // const isBigScreen = useMediaQuery({ query: "(min-device-width: 10px)" });
  // const isMobile = useMediaQuery({ query: "(max-width: 48px)" });

  
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  
  const handleClick = () => {
    setProgress(progress + 20);
  };

  
// generate publickey
  const getPublicKey = async() => { 
        Axios.get('https://mpowerweb.clinally.com/publicKey') 
        .then( (response) => {
          //  console.log('response :',response);

            if (response.status == 200) {
              
               sessionStorage.setItem('ABDMPublicKey', response.data);
              //  console.log('PUBLICKEY :',response.data)
              return response;
            }
         })
         .catch((err) => {
          console.log(err)
         })
}

//encryption of publickey
  const Encryption = async (publicKey) => {
    var publicKey = sessionStorage.getItem("ABDMPublicKey") ?? '';
    var padding = constants.RSA_PKCS1_OAEP_PADDING;
    var data = Buffer.from("ABDMPublicKey");
    var encryptedData = publicEncrypt({ key: publicKey, padding: padding }, data).toString('base64');
    sessionStorage.setItem('encryptedpk', encryptedData)
    // console.log("encrypted :" , encryptedData);         
    return encryptedData;
  }

// Encryption of otp using JSEncrypt    
  const otpencrytion = async () => {
    var data = otp;
    var key = sessionStorage.getItem("ABDMPublicKey") ?? '';
    // console.log(key)         
    var jsencrypt = new JSEncrypt();
    jsencrypt.setPublicKey(key);
    var encrypt = jsencrypt.encrypt(data)
    // console.log("encrypted otp :",encrypt)         
    return encrypt;
  }

//Verify MobileOtp
  const mOtpVerify = async (event) => {
    event.preventDefault();
    var accessToken = await sessionStorage.getItem("accessToken") ?? '';
    var publicKey = await getPublicKey();// sessionStorage.getItem("ABDMPublicKey") ?? '';
    var transactionId =  sessionStorage.getItem('transactionId') ?? '';
    var result = await Encryption(publicKey);
    var encryptedOTP = await otpencrytion();
    sessionStorage.setItem('encryptedOTP', encryptedOTP);
    navigate('/List');
  }

  const handleSubmit = (event) => {
     event.preventDefault();
    // setDisable(true)
  }

  const handleJustifyClick = (value) => {
    setOTP('');
    if (justifyActive === 'tab2'){ 
      setShowConfirmation(false);
      
      //setOTP('');
    }
    else{
      
    }
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  const lineStyle = {
    border: 0,
    height: '1px',
    backgroundColor: 'blue'
  };



const handleMobileNumberChange = async (e) => {
    // setMobileNumber(event.target.value);
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
       //this.setState({value: e.target.value})
       setMobileNumber(e.target.value);
    }
  
     };
  

// Make API request to send mobile OTP here
const Login = async (mobile) => {
  
    setFlag(false)
    let mNumber = mobileNumber;
    if (mNumber.length === 10 ) {
      var payload = {
        mobile : mNumber
      }
      await Axios.post('https://mpowerweb.clinally.com/getSessionToken', payload,
      ).then((response) => {
        //  console.log(response);
        if (response.status === 200) {
          setFlag(true)
          sessionStorage.setItem('accessToken', response.data['accessToken']);
          sessionStorage.setItem('transactionId', response.data['txnId']);
        }
      })
      var result = sessionStorage.getItem('accessToken');
      // console.log("result ", result)
      //  console.log("Data is from session : ", result);

      // const otpPayload =
      // {
      //   mobile: mobile
      // };

      // await Axios.post(`https://healthidsbx.abdm.gov.in/api/v2/registration/mobile/login/generateOtp`, otpPayload, 
      // {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: '*/*',
      //     Authorization: `Bearer ${result}`
      //   }
      // }
      // )
      //   .then((OTP) => {
      //     console.log("coming : ", OTP.status);
      //     sessionStorage.setItem('transactionId', OTP.data['txnId']);
      //     console.log('OTP SENT SUCCESSFULLY')
      //   })
      setShowOTP(true);
    }
    else {
      setShowAlert(true);  
    }
  };


  const handleOTPResend = () => {
    setResendTimer(60); //initial value
    let interval = setInterval(() => {
      setResendTimer(resendTimer - 1);
      if (resendTimer === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
  };

  const handleChange = (otp) => {
    setOTP(otp);
  }
  
  useEffect(() => {
    if (fullscreen) {
      setTimeout(() => {
        setFullscreen(false);
      }, 5000);
    }
  }, [fullscreen]);

const navigateTo = () => {
  navigate('/Register')
}
const bgimage ={
  backgroundImage:`url(${Image})`,
  backgroundSize:'cover',
   backgroungRepeat:'no-repeat',
   height:'100vh',
   opacity:'0.5'
};

  return (
     <div style={{ height: '100%',width:'100%',display:'flex', }}>
      <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img src={Logo} style={{ height: '40px' }} alt="raledaa" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>
     
     
      {/* <div class="row justify-content-center align-items-center h-100" */}
     <div class=""
      style={{
        position: "fixed",bottom: "25%",left: "32%",
    }}>
    
     {/* <MDBRow>  */}
           {/* <MDBCol  md='6'>
            {/* <MDBCard>
            <img src={newdoctor}
               className='img-fluid shadow-4'  alt="" />
            </MDBCard> */}
             
          {/* </MDBCol>  */}
       
  
         
              <MDBCol  md='6' >  
                <div
                  style={{
                    width: '500px',
                    height : '320px',
                    // position: 'absolute',
                  
                  }}
                > 
                  <MDBCard 
                      // class="border border-dark"
                      className='square border border-primary rounded-3'
                      style={{width: "490px",height:"320px"}} 
                    >
                      <MDBCardBody className="p-3 my-5 d-flex flex-column" style={{ paddingLeft: '90px' }}>
                        <MDBContainer  style={{ display: !flag ? 'block' : 'none' }} fluid>
                            <span  className="font-header "> 
                              <h4><p className="text-center">Already have Ayushman Bharat Health Account? Login Here</p></h4>  
                              </span >         
                                {!showOTP && <div className="text-center mb-4 mt-15 mr-50 ml-3" >

                                    {/* <MDBInput wrapperClass="mb-4 "
                                        label='Mobile Number'
                                        id='form1'
                                        type='Mobile'
                                        value={mobileNumber}
                                        onChange={handleMobileNumberChange}>
                                    </MDBInput> */}
                                    
                                    <MDBInput wrapperClass="mb-4 "
                                        maxLength={10}
                                          label='Mobile Number'
                                          id='form1'
                                          type='Mobile'
                                          value={mobileNumber}
                                          onChange={handleMobileNumberChange}>
                                      </MDBInput>
                                      

                                    <MDBBtn 
                                      rounded className='mx-2'
                                      style={{ backgroundColor: "#372e69" }}
                                      onClick={(e) => Login(e)}
                                    >
                                      Login
                                    </MDBBtn>
                                <br></br> <br></br>
                                    <div>
                                    <p className="text-center"> Not a member? <a href="#" onClick={() => navigateTo()}>Register</a></p>
                                    </div>
                                </div>}
                          </MDBContainer>

                          <MDBContainer  style={{ display: flag ? 'block' : 'none' }} fluid>
                                  {showOTP && <div className="text-center mb-4 mt-15 mr-50 ml-3">
                                    <span className="font-link" >
                                    <p class="elementor-heading-title elementor-size-default">Mobile Number Verification</p>
                                    </span>
                                    <span className="font-link" >
                                    <p class="elementor-heading-title elementor-size-default">Enter the OTP send to your Mobile Number : +91 {mobileNumber}<b class="text-danger"></b></p>
                                    </span>
                                    <div className="otp">
                                      <OTPInput
                                        onChange={handleChange}
                                        value={otp}
                                        inputStyle="inputStyle"
                                        numInputs={6}
                                        separator={<span></span>} />
                                    </div>
                                <br/>
                                    <MDBBtn 
                                      rounded className='mx-2'
                                      style={{ backgroundColor: "#372e69" }}
                                      onClick={(e) => mOtpVerify(e)}
                                    >
                                      SUBMIT
                                    </MDBBtn>
                                </div>}         
                          </MDBContainer>
        
                        </MDBCardBody>  
                  </MDBCard>
             
                </div> 
              </MDBCol> 

              
              
           {/* </MDBRow>  */}
      </div>
     
      <div class="fixed-bottom">
        <MDBFooter
            bgColor='dark'
            className='text-center text-lg-start text-muted'
        >
          <div
             className='text-center p-3' 
            style={{ backgroundColor: 'dark', color: "white" }}
          >
          Copyright © 2023 mPower Health.All Rights Reserved.
             
          <a className='text-white' href='https://aimhealthy.app/disclaimer/'>
              Disclaimer
          </a>
           
         </div>
        </MDBFooter>
      </div>

        <MDBContainer>
          <MDBAlert show={showAlert} color='primary'  id={alertId}
            autohide
              width={800}
              position='top-right'
              offset={50}
              delay={6000}
              appendToBody>
            Please Enter  MobileNumber
          </MDBAlert>
        </MDBContainer>
    </div>
  );
}
export default MobileNumberForm;