import OTPInput from "react-otp-input";
import React, { useState } from "react";
import Axios from 'axios'

import { 
         MDBCard,
         MDBCardBody,
         MDBBtn,
         MDBNavbar,
         MDBNavbarBrand,
         MDBContainer,
         MDBNavbarToggler,
         MDBIcon,
         MDBCardHeader,
         MDBFooter,
         MDBRow,
         MDBAlert, MDBCol
        } from "mdb-react-ui-kit";
import Logo from '../assets/clogo.png';
import Nhalogo from '../assets/NHA.png';
import ablogo from '../assets/abhalogo.png';
// import doctor from '../assets/Doctors.jpg';
import { useNavigate } from 'react-router-dom';
import "./Verify.css";

  
const Verify = (props) => {
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate()
  const [showNav, setShowNav] = useState(false);
  const [pin,setPIN] = useState('');
  const [showAlert, setShowAlert] = useState(false)
    const [alertId, setAlertId] = useState('')


 const  handleChange = (pin) => {
     setPIN(pin);
 }


 //verify mobile OTP
 async function verifyMobileOTP() {
  var accessToken = await sessionStorage.getItem('accessToken') ?? '';
  var txnId = await sessionStorage.getItem('txnId') ?? ''; 
  // console.log ("accessToken:",accessToken)
  // console.log ("txnId:",txnId)
  const otpDataBody = {
     otp : pin,
     txnId : txnId,
     accessToken : accessToken
  }
  await Axios.post(`https://mpowerweb.clinally.com/verifyMobileOTP`,otpDataBody,{
  headers : {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: `Bearer ${accessToken}`
   }
  }).then(async(otpResponse) =>{
    if(otpResponse.status ===200){
      // console.log("otp response:", otpResponse.status);
      sessionStorage.setItem('txnIdM', otpResponse.data['txnId']);
       var status =  await createHealthID();
      //  console.log("OTP VERIFIED")
       navigate('/CardDetails')
      
    } else if(otpResponse.status === 500) {
      setShowAlert(true);
    }
  })

}


 //create HealthId
 async function createHealthID() {
  var accessToken = await sessionStorage.getItem('accessToken') ?? '';
  var transactionId = await sessionStorage.getItem('txnId') ?? ''; 
  var abhaAddress = await sessionStorage.getItem('abhaAddress') ?? '';
  // console.log ("abhaAddress:",abhaAddress)
  // console.log ("transactionId:",transactionId)
  
  navigate('/CardDetails')

}



 return (
  <div id="videobackground">
  
  <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img src={Logo} style={{ height: '40px' }} alt="raledaa" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>
    

    <div class="d-flex justify-content-center"
    style={{
      position: "fixed",bottom: "25%",left: "32%",
  }}>

               <div class="shadow-5-strong">
                  <MDBRow>
                    <MDBCol>
                        <MDBCard 
                            style={{ width: "32rem" , height:'20rem'}}
                            color='deep-purple darken-1'
                            className='classic-admin-card white-text'
                        >
                            <MDBCardBody>
                                <div className='float-right'>
                                    <MDBIcon icon='people' />
                                </div>

                                <div className="text-center mb-4 mt-15 mr-50 ml-3">
                                  <h5> VERIFY MOBILE NUMBER</h5>
                                </div>
                               <div className="otpElements">
                               <span className="font-link"> <h6> Please Enter the OTP sent to your Mobile Number</h6></span> 
                                {/* <span className="font-link"> <h6>Enter your Otp here :</h6></span>   */}
                                  <div className="otp">
                                          <OTPInput
                                            onChange={handleChange}
                                            value={pin}
                                            inputStyle="inputStyle"
                                            numInputs={6}
                                            separator={<span></span>} />
                                        </div>
                              </div>
                                <div className="text-center mb-4 mt-15 mr-50 ml-3">
                                  <MDBBtn
                                      rounded className='mx-2'
                                      style={{ backgroundColor: "#372e69" }}
                                      onClick={(e) => verifyMobileOTP(e)} 
                                    >
                                     Verify and Generate ABHA CARD
                                   </MDBBtn>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>                  
                  </MDBRow>
                </div>
    </div>


    <MDBContainer>
      <MDBAlert show={showAlert} color='danger'  id={alertId}
         autohide
          width={800}
          position='top-right'
          offset={50}
          delay={6000}
          appendToBody>
      ABHA Server is busy. Please try again in sometime.
      </MDBAlert>
    </MDBContainer>

    <MDBFooter>
    {/* <div class="d-flex justify-content-center"> */}
       <MDBRow>
        {/* <img src={doctor} style={{ width:'500', height: '250px' }} alt="raledaa" /> */}
        </MDBRow>
      {/* </div> */}
    </MDBFooter>
 </div>
       
 );
}

export default Verify;