import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Logo from '../assets/clogo.png';
import Nhalogo from '../assets/NHA.png';
// import doctor from '../assets/Doctors.jpg';
import ablogo from '../assets/abhalogo.png';
import { useNavigate } from 'react-router-dom';
import OTPInput from "react-otp-input";
import "./Verify.css";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from 'buffer';
import JSEncrypt from 'jsencrypt';
import newdoctor from '../assets/newdoctor.png'

import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBInput,
  MDBCardBody,
  MDBCard,
  MDBIcon,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBProgress,
  MDBProgressBar,
  MDBValidation,
  MDBValidationItem,
  MDBCheckbox,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBFooter, MDBRow,MDBCol
} from 'mdb-react-ui-kit';
import { eventNames } from 'process';
import './login.css';


const Register = (props) => {

  const [progress, setProgress] = useState(0);
  const [showNav, setShowNav] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState('');
  // const [aOtp, seAtOTP] = useState('');

  const [justifyActive, setJustifyActive] = useState('tab1');
  const [resendTimer, setResendTimer] = useState(60);
  const navigate = useNavigate();
  const [confirmation, setShowConfirmation] = useState(false);
  const [topRightModal, setTopRightModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState([]);



  const handleClick = () => {
    setProgress(progress + 20);
  };

  const navigateTo = () => {
    navigate('/Login')
  }
  
// generate publickey
  async function getPublicKey()  { 
    // console.log("coming to " )
        Axios.get('http://healthidsbx.abdm.gov.in/api/v1/auth/cert', {
        headers : {
          'Content-Type': 'application/json', 
        }  
        })
        .then( (response) => {
            //  console.log('Hello :',response.status)
            if (response.status == 200) {
                  sessionStorage.setItem('ABDMPublicKey', response.data);
                  // console.log('PUBLICKEY :',response.data)
                return response;
             }
         })
}
  
const toggleShow = () => {
    setTopRightModal(!topRightModal);
    // setShowConfirmation(false);
    let c = document.getElementById('invalidCheck');
    //  console.log(c)
  };

//API request to send Aadhar OTP here
 const createAbha =async (aadhaar) => {
     await toggleShow();
     setFlag(false)
     let aNumber =  aadharNumber

    if (aNumber.length === 12) {
      const dataBody = {
        aadhaar : aNumber
      };

    await Axios.post('https://mpowerweb.clinally.com/getSessionTokenNgenerateAadhaarOTPAPI', dataBody,
      )
      .then((response) => {
          // console.log(response)
         if (response.status === 200) {
          setFlag(true)
          sessionStorage.setItem('accessToken', response.data['accessToken']);
          sessionStorage.setItem('txnId',response.data['txnId'])
         }
         var result = sessionStorage.getItem('accessToken')
      })
      .catch((error) => {
        console.log(error)
       })

    // console.log("Data is : ", result);
    //generate OTP for Aadhaar linked

    // var otpDataBody =
    // {
    //   aadhaar: aNumber
    // };

    // await Axios.post(`https://healthidsbx.abdm.gov.in/api/v1/registration/aadhaar/generateOtp`, otpDataBody, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: '*/*',
    //     Authorization: `Bearer ${result}`
    //   }
    // }
    // ).then((otpResponse) => {
    //   console.log("otp response---------------  ",otpResponse.data.txnId);

    //   sessionStorage.setItem('txnId', otpResponse.data.txnId);
    //   console.log("AADHAR OTP SENT SUCCESSFULLY");
    // })
    setShowOTP(true);
  }
  };

// Verify aadhar otp  
const  verifyAadhaarOTPAPI = async (event) => {
  //let aOtp = event.target.value
  
 var accessToken = await sessionStorage.getItem("accessToken") ?? '';
 var publicKey = await sessionStorage.getItem("ABDMPublicKey") ?? '';
 var transactionId = await sessionStorage.getItem("txnId") ?? '';
// verify OTP for aadhaar  
   // console.log('transactionId-- ' , transactionId);
   // console.log('otp-- ', otp)

 var otpDataBody = { 
      otp : otp,
     txnId : transactionId,
     accessToken : accessToken
    }
await Axios.post('https://mpowerweb.clinally.com/verifyAadhaarOTPAPI', otpDataBody,{
  headers : {
      'Content-Type': 'application/json',
       Accept: '*/*',
       Authorization: `Bearer ${accessToken}`
   }
 }).then((otpResponse) => {
    //  console.log("otpResponse:", otpResponse) 
  if(otpResponse.status === 200) {
    // console.log('response:', otpResponse.data)
   // sessionStorage.setItem('transactionId',otpResponse.data)
  //  console.log('Aadhar verification Successfull')
   navigate('/CreateAbha')
  }

 }).catch((error)=> {
   console.log(error);
 })
}



  const handleJustifyClick = (value) => {
    setOTP('');
    if (justifyActive === 'tab2'){ 
      setShowConfirmation(false);
      
      //setOTP('');
    }
    else{
      
    }
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  const lineStyle = {
    border: 0,
    height: '1px',
    backgroundColor: 'blue'
  };


  const handleAadharNumberChange = async (event) => {
    // setAadharNumber(event.target.value);

    // if (event.target.value.length === 12) {

    //   // Make API request to send OTP here
    //  };
   
      const re = /^[0-9\b]+$/;
  
      if (event.target.value === '' || re.test(event.target.value)) {
         //this.setState({value: e.target.value})
         setAadharNumber(event.target.value);
      }
 };
    


 
  const handleOTPResend = () => {
    setResendTimer(60); //initial value
    let interval = setInterval(() => {
      setResendTimer(resendTimer - 1);
      if (resendTimer === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
  };

  const handleChange = (otp) => {
    setOTP(otp);
  }
  
//   useEffect(() => {
//     if (fullscreen) {
//       setTimeout(() => {
//         setFullscreen(false);
//       }, 5000);
//     }
//   }, [fullscreen]);


  return (

    <div  style={{ height: '90%' }}>
      
      <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img src={Logo} style={{ height: '40px' }} alt="raledaa" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>
     
      <div  style={{
          position: 'relative',
          bottom: '30%',
          marginLeft: '28%',
          marginTop:'13%'
        }}> 
       
      <MDBRow>
           {/* <MDBCol  md='6'>
              <img src={newdoctor}  className='img-fluid shadow-4'  style={{  height: '300px' }} alt="raledaa" />
          </MDBCol>  */}
       

       <MDBCol md='1'>

       </MDBCol>
          <MDBCol md='5' className='mb-4' >
            <MDBCard 
                className='square border border-warning rounded-3'
                    
                  style={{width: "33rem",height:"19.5rem"}}   
            >
               <MDBCardBody 
                    className="p-3 my-5 d-flex flex-column" 
                >
                  <MDBContainer  style={{ display: !flag ? 'block' : 'none' }} fluid>
                    <span className="font-header ">  
                    <h3><p className="text-center">Create Ayushman Bharat Health Account</p></h3>
                    </span >
                    <span className="font-header ">
                      {/* <h4><p className="text-center">ABHA (Health ID) Card</p></h4>  */}
                    </span>
                        <div className="text-center mb-4 mt-15 mr-50 ml-3">
                          <MDBInput wrapperClass='mb-4'
                          maxLength={12}
                            label='Aadhar Number'
                            value={aadharNumber}
                            id='form1'
                            type='Aadhar'
                            onChange={handleAadharNumberChange}
                        />
                        <MDBBtn 
                            rounded className='mx-2'
                            style={{ backgroundColor: "#372e69" }}
                            onClick={toggleShow}
                        >
                            Create ABHA
                        </MDBBtn>
                        <br></br> <br></br>
                        <p className="text-center">Already a member? <a href="/" onClick={() => navigateTo()}>Login</a></p>
                      
                      <br></br>
                      <div>
                      <p>APPROVED BY <img src={Nhalogo} style={{ height: '90px' }} alt="NHALogo" /></p>
                      
                      </div>


                        <MDBModal show={topRightModal} setShow={setTopRightModal} tabIndex='-1'>
                            <MDBModalDialog position='top' frame>
                              <MDBModalContent>
                                <MDBModalHeader>
                                  <MDBModalTitle>
                                    Agree Terms and Conditions
                                  </MDBModalTitle>
                                  <MDBBtn
                                      className='btn-close' 
                                      color='none' 
                                      onClick={toggleShow}>  
                                  </MDBBtn>
                                </MDBModalHeader>

                                <MDBModalBody>
                                  <p className='text-start'>
                                  I, hereby declare that I am voluntarily sharing my Aadhaar Number and demographic information issued by UIDAI,
                                  with National Health Authority (NHA) for the sole purpose of creation of ABHA number .
                                  I understand that my ABHA number can be used and shared for purposes as may be notified by ABDM from time to time including provision of healthcare services.
                                  Further, I am aware that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph)
                                  may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which inter alia includes stakeholders and entities such as healthcare professionals (e.g. doctors),
                                  facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there under.
                                  I authorize NHA to use my Aadhaar number for performing Aadhaar based authentication with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies, Benefits and Services) Act,
                                  2016 for the aforesaid purpose. I understand that UIDAI will share my e-KYC details, or response of “Yes” with NHA upon successful authentication.
                                  I have been duly informed about the option of using other IDs apart from Aadhaar; however,
                                  I consciously choose to use Aadhaar number for the purpose of availing benefits across the NDHE.
                                  I am aware that my personal identifiable information excluding Aadhaar number / VID number can be used and shared for purposes as mentioned above.
                                  I reserve the right to revoke the given consent at any point of time as per provisions of Aadhaar Act and Regulations.
                                  </p>
                                </MDBModalBody>

                                <div class="d-flex justify-content-center">
                                  <MDBValidation className='row g-3'>
                                    <MDBValidationItem 
                                      className='col-12' 
                                      feedback='You must agree before submitting.' 
                                      invalid
                                    >
                                      <MDBCheckbox label='Agree to terms and conditions' id='invalidCheck' required />
                                    <br></br>
                                    
                                      <MDBBtn 
                                          className="me-3 " 
                                          color='secondary' 
                                          onClick={toggleShow}
                                      >
                                        CLOSE
                                      </MDBBtn>
                                    
                                      <MDBBtn 
                                        className="me-3" 
                                        style={{ backgroundColor: "#003362" }} 
                                        onClick={createAbha}>
                                        I AGREE
                                      </MDBBtn>
                                    <br></br> <br></br>
                                    </MDBValidationItem>
                                  </MDBValidation>
                                </div>
                              </MDBModalContent>
                            </MDBModalDialog>
                        </MDBModal> 
                    </div>
              </MDBContainer>
             
              <MDBContainer  style={{ display: flag ? 'block' : 'none' }} fluid>
                <div className="text-center mb-4 mt-15 mr-50 ml-3">
                  <span  className="font-link"><h5 class="m-0">Confirmation</h5></span> 
                  <br></br>
                    <span  className="font-link">
                       <h6>Enter the OTP sent to your Aadhar Linked MobileNumber</h6> 
                    </span>
                    
                     <div className="otp">
                        <OTPInput
                          onChange={handleChange}
                          value={otp}
                          inputStyle="inputStyle"
                          numInputs={6}
                          separator={<span></span>} 
                          />
                      </div>
        
                      <div className="text-center mb-4 mt-5 mr-3 ml-3">
                        <MDBBtn 
                            rounded
                            className='mx-2'
                            style={{ backgroundColor: "#372e69" }}
                            onClick={(e) => verifyAadhaarOTPAPI(e)}
                        >
                            SUBMIT
                        </MDBBtn>
                      </div>
                      <br/>
                      <div>
                      <p>APPROVED BY <img src={Nhalogo} style={{ height: '100px' }} alt="NHALogo" /></p>
                      </div>
                </div> 
              </MDBContainer>   
           </MDBCardBody>   
         </MDBCard>
         </MDBCol>
    </MDBRow>
 
   </div>
       
{/* 
      <MDBRow> 
        <MDBFooter 
            bgColor='dark'
          className='text-center text-lg-start text-muted'
        >
          <div className='text-center p-4' style={{ backgroundColor: 'dark', color: "white" }}>
            © 2023 Copyright:
            <a className='text-reset fw-bold' href='https://www.clinally.com/'>
              ClinAlly
            </a>
          </div>
        </MDBFooter>
      </MDBRow> */}
  </div>
 );
}
export default Register;