import React, { useState, useEffect, useRef, useCallback} from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/clogo.png';
import Nhalogo from '../assets/NHA.png';
import ablogo from '../assets/abhalogo.png';
import {MDBRow,
   MDBCol,
   MDBCard,
   MDBCardBody, 
   MDBNavbarItem,
   MDBNavbarLink,
   MDBContainer,
   MDBNavbar,
   MDBNavbarBrand,
   MDBNavbarToggler,
   MDBNavbarNav,
   MDBSideNav,
   MDBSideNavMenu,
   MDBSideNavItem,
   MDBSideNavLink,
   MDBSideNavCollapse,
   MDBIcon, MDBTabs,MDBTabsItem,MDBTabsContent,MDBTabsLink,MDBTabsPane,
   MDBBtn,MDBModalDialog,MDBModalContent,
   MDBCardHeader,MDBInput,MDBModalHeader,MDBModalBody,MDBModal}
   from 'mdb-react-ui-kit';

   import {Buffer} from 'buffer';
   import format from 'date-fns/format';

   const PNGCard = (props) => {
        const [showNav, setShowNav] = useState(false);
        const [basicCollapse1, setBasicCollapse1] = useState(true);
        const [basicCollapse2, setBasicCollapse2] = useState(true);
        const [mode, setMode] = useState('side');
        const [pngCard,setPNGCARD] = useState();
        const navigate = useNavigate()
        const [name, setName] = useState();
        const [profileImage,setProfileImage] = useState();
        const contentRef = useRef(null);
        const[fullName, setFullName] = useState('');
        const [dob, setDOB] =useState('');
        const[email,setEmail] = useState('');
        const[address ,setAddress] = useState('');
        const [healthId, setHealthID] = useState('');
        const [healthIdNumber, setHealthIdNumber] = useState();
        const [mobile,setMobile] = useState('');
        const[state,setState] = useState('');
        const [justifyActive, setJustifyActive] = useState('tab1');
        const [confirmation, setShowConfirmation] = useState(false);
        const [flag, setFlag] = useState(false);
        const [firstName,setFirstName] = useState('');
        const [middleName,setMiddleName] = useState('');
        const [lastName,setLastName] = useState('');

        const[modal, setModal] = useState(false);


const  logout = () => {
    sessionStorage.clear()
    window.location.href = '/'
  }

  const handleJustifyClick = (value) => {
    if (justifyActive === 'tab2'){ 
      setShowConfirmation(false);
      
      //setOTP('');
    }
    else{
      
    }
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
   // setDisable(true)
 } 

const toggleModal = () => {
setModal(!modal)
  }
const handleResize = useCallback(() => {
   if (window.matchMedia('(min-width: 900px)')) 
      {
         return setMode('side');
      }
        return setMode('push');
}, []);
 
   
 useEffect(() => {
      window.addEventListener('resize', handleResize);
       return () => {
         window.removeEventListener('resize', handleResize);
      } } ,[handleResize]);

useEffect(() => {

  async function PngCard() {
     var accessToken = await sessionStorage.getItem('accessToken') ?? '';
     var transactionId = await sessionStorage.getItem('txnId') ?? ''; 
     var abhaAddress = await sessionStorage.getItem('abhaAddress') ?? '';

    const otpDataBody = {
      healthId: abhaAddress ,
      txnId: transactionId,
      accessToken : accessToken
    }
    await Axios.post(`https://mpowerweb.clinally.com/createHealthID`,otpDataBody)
    .then (async(response) => {
      // console.log(response)
      if(response.status === 200) {
        // console.log("HEALTHID CREATED");
        // console.log(response.data)
        let date = new Date(response.data.yearOfBirth + '-' + response.data.monthOfBirth + '-' + response.data.dayOfBirth);
         
        sessionStorage.setItem('loginData', response.data['token']);
        // sessionStorage.setItem('token', otpResponse.data.token);
        sessionStorage.setItem('healthId', JSON.stringify(response.data.healthId));
        sessionStorage.setItem('healthIdNumber', JSON.stringify(response.data.healthIdNumber));
         setName(response.data.firstName  + ' ' + response.data.lastName );
         setFirstName(response.data.firstName)
         setMiddleName(response.data.middleName)
         setLastName(response.data.lastName)
         setProfileImage(response.data.kycPhoto)
         setFullName(response.data.name )
         setDOB(format(date,'MMM d,yyyy').toString()) 
         setHealthID(response.data.healthId)
         setHealthIdNumber(response.data.healthIdNumber)
         setMobile(response.data.mobile)
         setState(response.data.stateName)
         setAddress(response.data.address)
         await getPNGCard() ; 
      } else {
        // return response.status;
        // navigate('/Verify')
      
      }
    })
    .catch (async(error) => {
      console.log(error.response.status)
      if(error.response.status = 500) {
      // navigate('/CreateAbha')
      }
  })
  }     
  PngCard();      
  })

//Get PNG Card
   async function getPNGCard()  {
   
    var accessToken = await sessionStorage.getItem("accessToken") ?? '';
    var token =  await sessionStorage.getItem("loginData") ?? '';
    
    await Axios.post('https://mpowerweb.clinally.com/getPNGCardAPIR', {
      accessToken: accessToken,
      token : token 
    }, 
    {responseType: 'arraybuffer'} )
   .then(async (otpResponse) => {
    // console.log("Verify OTP Response:" ,otpResponse.status)
  
    if (otpResponse.status == 200) {
      // console.log("PNG Card :",otpResponse.status);
      // console.log('PNG Card')
     let base64ImageString = Buffer.from(otpResponse.data, 'utf8').toString('base64')
      setPNGCARD(base64ImageString);
      navigate('/CardDetails')
    }
  })
    // return Uint8List(0);
}

 return (
    <div  style={{ height: '100%' }} >
      
      <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='https://www.clinally.com/'>
            <img src={Logo} style={{ height: '40px' }}  />
          </MDBNavbarBrand>
          {/* <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler> */}
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>
     
  {/* <MDBNavbar variant="light" dark expand='md' style={{ zIndex: 1,height:'50px' ,backgroundColor: "white" }}>
       
       <MDBContainer fluid>
         <MDBNavbarBrand>
         <div  className='text-center'>
         
           </div>
         
         </MDBNavbarBrand>
           <span className='navbar-text'><span style={{color: 'black', paddingRight:'5px'}}>{name}</span>   
     
             <img src={`data:image/png;base64,${profileImage}`}  className='img-fluid rounded-circle' style={{ height: '55px', padding: '0', backgroundColor:'white' }}  alt="phfilogo" /></span>
        </MDBContainer>
  </MDBNavbar> */}

   <MDBRow>
     {/* <MDBCol md='4'>
       <MDBSideNav backdrop={false} mode={mode} color='dark' closeOnEsc={false}>
        <div className='mt-4'>
          <div id='header-content' className='ps-3'>
            <img id='profileImage' src={`data:image/png;base64,${profileImage}`}   className='rounded-circle img-fluid mb-3'
              style={{ maxWidth: '50px' }} /> 
            <h4>
              <span style={{ whiteSpace: 'nowrap' }}>{name}</span>
            </h4>
           
          </div>
          <hr className='mb-0' />
        </div>
        <MDBSideNavMenu>
          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='address-book' className='fas fa-fw me-3'  />
              Update Details
            </MDBSideNavLink>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='file' className='fas fa-fw me-3' />
              Reset Password
            </MDBSideNavLink>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='heart' className='fas fa-fw me-3' />
              Re-KYC verification
            </MDBSideNavLink>
          </MDBSideNavItem>
    
          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='trash' className='fas fa-fw me-3' />
                Delete/Deactivate
            </MDBSideNavLink>
          </MDBSideNavItem>
          
          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='user-circle' className='fas fa-fw me-3' />
              Personal
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>
        <hr className='m-0' />
        <MDBSideNavMenu>
          <MDBSideNavItem>
          <MDBSideNavLink>
              <a onClick= {(e) => logout(e) }>
              <MDBIcon icon='user-astronaut' className='fas fa-fw me-3' />
              Logout
              </a>
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>     
      </MDBSideNav> 
    </MDBCol> */}
    </MDBRow>
  
     
      {/* <MDBRow>
        <MDBCol md='8'>
        <p className="float-end"><a href="#" onClick={toggleModal} ><b>View Details</b></a></p> 
        </MDBCol>
      </MDBRow>
      */}

<div class="d-flex justify-content-center"
style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          }}
>
        <MDBCard>
          {/* <span class="border border-dark"> */}
          <span   className='square  border border-2 rounded-4'
                    >  
            <MDBCardBody >
              <form onSubmit={handleSubmit} style={{ display: !flag ? 'block' : 'none' }}>
                <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
                  <MDBTabsItem>
                    <MDBTabsLink id='loginTab' className='login--form--submit' 
                      onClick={() => handleJustifyClick('tab1')} 
                      active={justifyActive === 'tab1'} >
                      <h6> ABHA Card </h6>
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink id='regTab'
                      onClick={() => handleJustifyClick('tab2')} 
                      active={justifyActive === 'tab2'}>
                      <h6>Profile Details</h6>
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent >
                  <MDBTabsPane show={justifyActive === 'tab1'}>
                  
                      <MDBRow class="d-flex justify-content-center">
                        <MDBCol xl='4' md='4' className='mb-4'>
                            <MDBCard  class="d-flex justify-content-center">   
                              <div class="d-flex justify-content-center">       
                                <img id='pngCardImg' src={`data:image/png;base64,${pngCard}`} style={{width:500, height:250}}  /> 
                              </div>
                          </MDBCard>              
                        </MDBCol>
                    </MDBRow> 
                  
                  
                  </MDBTabsPane>
            
                  <MDBTabsPane show={justifyActive === 'tab2'}>
                    <MDBRow >
                      <MDBCol  xl='4' md='4' className='mb-4'>
                       <MDBCard class="d-flex justify-content-center" style={ {width : "25rem"} }>
                         <MDBContainer>
                          <div >
                              <p ><span><b style={{"color": "#000080" ,fontSize:'16px'}}>FirstName</b> : {firstName}</span></p>
                              <p ><span><b style={{"color": "#000080" ,fontSize:'16px'}}>MiddleName</b> : {middleName}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'16px'}}>LastName</b> : {lastName} </span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'16px'}}>ABHA Address</b> : {healthId}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'16px'}}>ABHA ID </b>     : {healthIdNumber}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'16px'}}>Mobile Number</b>:  {mobile}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'16px'}}>Email </b>       : {email}</span></p>
                              {/* <p><span><b>DateOfBirth</b> : {dob}</span></p> */}
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>Address</b>   : {address}</span></p>
                           </div>
                          </MDBContainer>
                         

                     </MDBCard>
                     </MDBCol>
                     </MDBRow>
                  </MDBTabsPane>
                </MDBTabsContent>
              </form>
            </MDBCardBody>
          </span>
        </MDBCard>
        <br></br>
      </div>


      {/* <MDBModal show={modal} setShow={setModal} tabIndex='-1'>  
           <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                 Profile Details
              </MDBModalHeader>
              
               <MDBModalBody className="mx-3">
               
                   <MDBRow>
                    <MDBCol>
                      <MDBInput 
                         className='mb-4'     
                          id="firstName" label="First Name" 
                          value={fullName}
                      />
                      </MDBCol>
                     </MDBRow>
                     <MDBRow>
                       <MDBCol>
                        <MDBInput
                          className='mb-4'   
                          id="dateofBirth" label="Date of Birth" 
                          value={dob} 
                        /> 
                      </MDBCol>
                    </MDBRow>
                   
                   <MDBRow>
                      <MDBCol>
                      <MDBInput 
                        className='mb-4'  
                         id="email" label="Email"  
                         value={email}
                      /> 
                      </MDBCol>

                   </MDBRow>
                    <MDBRow>
                       <MDBCol>
                        <MDBInput 
                          className='mb-4'  
                          id="gender" label="Gender"
                          value={gender}  
                        />
                      </MDBCol>
                    </MDBRow>
                     
                     <MDBRow>
                      <MDBCol>
                        <MDBInput  
                          className='mb-4' 
                            id="healthId" label="HealthID" 
                            value={healthId}
                        />
                       </MDBCol>
                     </MDBRow>

                     <MDBRow>
                      <MDBCol>
                      <MDBInput 
                       className='mb-4'  
                          id="healthIdNumber" label="HealthID Number" value={healthIdNumber}
                      />
                      </MDBCol>
                      </MDBRow>   
                       
                       <MDBRow>
                         <MDBCol>
                          <MDBInput  
                            className='mb-4' 
                            id="mobile" label="Mobile"  value={mobile}
                          /> 
                         </MDBCol>
                       </MDBRow>
                    
                     <MDBRow>
                       <MDBCol>
                       <MDBInput 
                        className='mb-4'  
                         id="statename" label="State Name"   value={state}
                      /> 
                       </MDBCol>
                     </MDBRow>
                     
                       
                       
                    <MDBBtn className="mb-4" color="info"
                         onClick= {toggleModal}>CLOSE </MDBBtn>
                  
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
        </MDBModal> */}
  
    </div>
  
 )
   }                                                  
 export default PNGCard