import React, { useState} from 'react';
import Logo from '../assets/clogo.png';
import ablogo from '../assets/abhalogo.png';
import Nhalogo from '../assets/NHA.png';
import Axios from 'axios';

import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBInput,
    MDBBtn,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBContainer,
    MDBAlert
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom'


const Idotp = (props) => {

    const [showNav, setShowNav] = useState(false);
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState('');
    const[healthId, setHealthID] = useState()
    const [mobile, setMobile] = useState()
    const [otp, setOTP] = useState('')
    const [ErrMobile, setErrMobile] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertId, setAlertId] = useState('')
    const onlyNumbers = /^[0-9]+$/;
    

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (onlyNumbers.test(newValue) && newValue.length <= 10) {
            setInputValue(newValue);
        }
    };
 
    const handleHealthIdChange = async (event) => {
        setHealthID(event.target.value);
    }


    const handleMobilechange = async (e) => {
        setMobile(e.target.value);
        // if (onlyNumbers.test(e.target.value) && e.target.value.length <= 10) {
        //     setMobile(e.target.value);
        //     } 
    }


 

//getSessionTokenAPI(abhaId)
 async function getSessionTokenAPI(abhaId) {
   
 const  dataBody = {
    healthId: healthId
  }
    await Axios.post(`https://mpowerweb.clinally.com/getSessionTokenNgenerateMobileOTPAPI`, dataBody,{
       headers : {
        'Content-Type': 'application/json',
       }
    }
    )
   .then((response) => {
    // console.log(response)
        if(response.data.status === 422) {
          // console.log("otpResponse:", response.data.status)
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          var status =  generateMobileOTP();
        
        }
        else if(response.data.status === 200){
          setShowAlert(true);
        } 
        else {
            return response.status;  
        }
        //  console.log(otpResponse.data)
        // sessionStorage.setItem('accessTokenA', otpResponse.data['accessToken']);
            
      //generate OTP for mobile
        // const otpDataBody = {
        //   healthId: healthId,
        // };
        // Axios.post(`https://healthidsbx.abdm.gov.in/api/v1/search/searchByHealthId`, otpDataBody,{
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Accept: '*/*',
        //       Authorization: `Bearer ${accessToken}`
        //   }
          
        // }).then((response) => {
        //     if(response.status === 422) {
        //       console.log("otpResponse:", response.status)
        //       var status =  generateMobileOTP();
        //       sessionStorage.setItem("abhaAddress", response.data.healthId);
        //         console.log(response.data.healthId)
        //     return status;
          
        //   }
        // else if(response.status === 200){
        //   console.log(response.data.healthId)
        //   setShowAlert(true);
        //   } 
        //   else {
        //     return response.status;  
        //   }
     }
   )
    .catch (async (error) => {
         console.log(error)
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          // console.log(dataBody.healthId)   
          var status =  await  generateMobileOTP();
          return status;
    })
  }
      
 
   




//generate mobile otp
async function generateMobileOTP() {
    var accessToken = await sessionStorage.getItem('accessToken') ?? '';
    var txnId = await sessionStorage.getItem('txnId') ?? '';
   
    const otpDataBody = {
      mobile : mobile,
      txnId : txnId,
      accessToken : accessToken
    }
    
    await Axios.post(`https://mpowerweb.clinally.com/generateMobileOTP`, otpDataBody)
    .then ((otpResponse) => {
      if(otpResponse.status === 200) {
        // console.log ("OTP SENT")
        sessionStorage.setItem("NtxnId", otpResponse.data['txnId']);
       navigate('/Verify')
      }
      else {
        
      }
    })
  }
  
return (

        <div style={{ height: '100%' }} >
    <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img src={Logo} style={{ height: '40px' }} alt="raledaa" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>
          

            <div class="d-flex justify-content-center"
             style={{
              position: "fixed",bottom: "25%",left: "32%",
          }}>
          
               <div class="shadow-5-strong">
                  <MDBRow>
                        <MDBCard 
                            style={{ width: "33rem" }}
                            color='deep-purple darken-1'
                            className='classic-admin-card white-text'
                        >
                            <MDBCardBody>
                                <div className='float-right'>
                                    <MDBIcon icon='people' />
                                </div>

                                <div className="text-center mb-4 mt-15 mr-50 ml-3">
                                  <h4> CREATE ABHA ACCOUNT</h4>
                                </div>

                                <MDBInput onChange= {handleHealthIdChange} value = {healthId}
                                    label='Create Your ABHA Id' 
                                    id='text'
                                     type='ID'
                                />
                                  <br></br>
                                  <p>Ex:UserName@abdm</p>
                                <MDBInput 
                                    label='Enter Mobile Number'
                                    id='typePhone'
                                    value={mobile} 
                                    onChange={handleMobilechange} 
                                    required
                                   
                                />
                                <div className="text-center mb-4 mt-15 mr-50 ml-3">
                                   <br></br>
                                    <MDBBtn
                                      rounded className='mx-2'
                                      style={{ backgroundColor: "#372e69" }}
                                      onClick={(e) => getSessionTokenAPI(e)} 
                                    >
                                      CONTINUE
                                   </MDBBtn>
                                </div>
                            </MDBCardBody>
                        </MDBCard>                  
                  </MDBRow>
                </div>
            </div>

            <MDBContainer>
      <MDBAlert show={showAlert} color='primary'  id={alertId}
         autohide
          width={800}
          position='top-right'
          offset={50}
          delay={6000}
          appendToBody>
      USER ALREADY EXISTS!
      </MDBAlert>
      </MDBContainer>
        </div>
    );

}

export default Idotp;

