import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBCollapse,
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,MDBTabsItem,
    MDBSideNavCollapse,MDBBtn,MDBTabs,MDBTabsContent,MDBTabsPane,MDBTabsLink,
    MDBIcon,MDBInput,MDBModal,MDBModalHeader,MDBModalBody,MDBModalDialog, MDBModalContent

} from 'mdb-react-ui-kit';
import React, { useState, useEffect, useRef, useCallback} from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {Buffer} from 'buffer';
import Logo from '../assets/clogo.png';
import Nhalogo from '../assets/NHA.png';
import ablogo from '../assets/abhalogo.png';
import { format } from 'date-fns'

import image from '../assets/health-id-card.png';

const Card = (props) => {
  const [showNav, setShowNav] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(true);
  const [mode, setMode] = useState('side');
  const navigate = useNavigate();
  const [pngCard,setPNGCARD] = useState();
  const [profileImage,setProfileImage] = useState();
  const [name,setName] = useState();
  const[fullName, setFullName] = useState('');
  const [dob, setDOB] =useState('');
  const[email,setEmail] = useState('');
  const[address ,setAddress] = useState('');
  const [healthId, setHealthID] = useState('');
  const [healthIdNumber, setHealthIdNumber] = useState();
  const [mobile,setMobile] = useState('');
  const[state,setState] = useState('');
  const [justifyActive, setJustifyActive] = useState('tab1');
  const [confirmation, setShowConfirmation] = useState(false);
  const [flag, setFlag] = useState(false);
  const [firstName,setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName,setLastName] = useState('');
  
 
  const[modal, setModal] = useState(false);
  const contentRef = useRef(null);


  const handleJustifyClick = (value) => {
    if (justifyActive === 'tab2'){ 
      setShowConfirmation(false);
      
      //setOTP('');
    }
    else{
      
    }
    if (value === justifyActive) {
      return;
    }
    setJustifyActive(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
   // setDisable(true)
 } 

  const toggleModal = () => {
    setModal(!modal)
      }
  const handleResize = useCallback(() => {
    if (window.matchMedia('(min-width: 900px)')) {
      return setMode('side');
    }
    return setMode('push');
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);


  useEffect(() => {
    async function card() {
      var profileId = await sessionStorage.getItem("profileId") ?? '';
      var accessToken = await sessionStorage.getItem("accessToken") ?? '';
      var txnId = await sessionStorage.getItem("txnId") ?? '';
      var token = await sessionStorage.getItem("token") ?? '';
      //var loginResponse = await sessionStorage.getItem('loginResponse') ?? '';
      var jsonData = await sessionStorage.getItem('mobileLinkedHid') ?? '';//
      // console.log("jsonData",jsonData)
      var linkedIds;
      // console.log(JSON.parse(jsonData));
      if(jsonData!== null && jsonData !== '')
      {
        linkedIds = JSON.parse(jsonData);
      }
    
      //GetUserAuthorizedToken
      //generate OTP for mobile
     
      const getTokenBody = {
        healthId: linkedIds[profileId].healthIdNumber,
        txnId : txnId ,
        accessToken : accessToken,
        token : token
      };
    
    
      await Axios.post(`https://mpowerweb.clinally.com/getUserAuthorizedTokenAPI`, getTokenBody,{
        headers: {
         'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: 'Bearer ' + accessToken,
         'T-Token': 'Bearer ' + (token)
        }
   }).then(async(tokNProfileRes) => {
     //console.log("Token Response: ",tokenResponse.status);
   
    if (tokNProfileRes.status == 200) {
    //  console.log("Token Response Body Mottham Details vastunaya :",  tokNProfileRes);
 
     sessionStorage.setItem('loginData',tokNProfileRes.data.token);
     //var profileToken = tokenResponse;

     //get account profile details
     //GetUserAuthorizedToken
    
    //  Axios.get('https://healthidsbx.abdm.gov.in/api/v1/account/profile',{
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: '*/*',
    //     Authorization: 'Bearer ' + accessToken,
    //     'X-Token': 'Bearer ' + (profileToken ?? '')
    //   }
    //  }).then(async(profileResponse) => {
    //    //console.log("Profile Response:", profileResponse.status);
    //      console.log("Profile Response Body:",profileResponse);
       let date = new Date(tokNProfileRes.data.yearOfBirth + '-' + tokNProfileRes.data.monthOfBirth + '-' + tokNProfileRes.data.dayOfBirth);
        setName(tokNProfileRes.data.firstName  + ' ' + tokNProfileRes.data.lastName )
        setFirstName(tokNProfileRes.data.firstName)
        setMiddleName(tokNProfileRes.data.middleName)
        setLastName(tokNProfileRes.data.lastName)
        setProfileImage(tokNProfileRes.data.kycPhoto)
        setFullName(tokNProfileRes.data.name )
        setDOB(format(date,'MMM d,yyyy').toString()) 
        setHealthID(tokNProfileRes.data.healthId)
        setHealthIdNumber(tokNProfileRes.data.healthIdNumber)
        setMobile(tokNProfileRes.data.mobile)
        setState(tokNProfileRes.data.stateName)
        setAddress(tokNProfileRes.data.address)
      await getPNGCard(); 
    //  })
   }
   else {
      sessionStorage.setItem('profileData', '');
      navigate('/List')
   }
   return tokNProfileRes.status;
 })
 }
  card();
   }, [])


//Get PNG Card
 async function getPNGCard()  {
   
  var accessToken = await sessionStorage.getItem("accessToken") ?? '';
  var token =  await sessionStorage.getItem('loginData') ?? '';
  // console.log(token)


  //generate  CARD
  await Axios.post('https://mpowerweb.clinally.com/getPNGCardAPI', {
    accessToken: accessToken,
    token : token 
  }, {responseType: 'arraybuffer'} )
  .then(async (response) => {
    // console.log(Response)
      if (response.status === 200) {
        // console.log('PNG Card',Response);
        let base64ImageString = Buffer.from(response.data, 'utf8').toString('base64')
        setPNGCARD(base64ImageString);
       
      }
  })
  .catch((error) => {
    console.log(error)
  })
  // return Uint8List(0);
}


const  logout = () => {
  sessionStorage.clear()
  window.location.href = '/'
}


return (
  <div>

     <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='Login'>
            <img src={Logo} style={{ height: '40px' }} alt="raledaa" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>

      {/* <MDBNavbar variant="light" dark expand='md' style={{ zIndex: 1,height:'50px' ,backgroundColor: "white" }}>
         <MDBNavbarBrand>
          <div  className='text-center'>

          </div>
         </MDBNavbarBrand>
        <MDBRow  >   
         < MDBCol className='float' md='6'> */}
              {/* <img id='profileImage' 
              src={`data:image/png;base64,${profileImage}`}  
              className='img-fluid rounded-circle'
               style={{width:'20%', height:'30%'}}  
              />  */}
                {/* <h6>
              <span><center>{name}</center></span>
            </h6>
            </MDBCol> 
            <MDBCol>
            <div class="col-xl-3 col-md-4 mb-3 text-left">
             <img id='profileImage' 
              src={`data:image/png;base64,${profileImage}`}  
              className='img-fluid rounded-circle'
               style={{width:'120%', height:'70%'}}  
              />
              </div>  
            </MDBCol>
          </MDBRow> 
      </MDBNavbar> */}
{/*      
     <MDBNavbar  variant="light" dark expand='md' style={{ zIndex: 1,height:'50px' ,backgroundColor: "white" }}>
       
      <MDBContainer fluid>
        <MDBNavbarBrand>
        <div  className='text-center'>
        
          </div>
        
        </MDBNavbarBrand>
          <span className='navbar-text'><span style={{color: 'black', paddingRight:'5px'}}>{name}</span>   
    
            <img src={`data:image/png;base64,${profileImage}`}  className='img-fluid rounded-circle' style={{ height: '55px', padding: '0', backgroundColor:'white' }}  alt="phfilogo" /></span>
       </MDBContainer>
     </MDBNavbar> */}
     <br></br>
   
   
     {/* <MDBSideNav backdrop={false} mode={mode} color='dark' closeOnEsc={false}>
        <div className='mt-4'>
          <div id='header-content' className='ps-3'>
              <img id='profileImage' 
              src={`data:image/png;base64,${profileImage}`}  
               className='rounded-circle img-fluid mb-3'
              style={{ maxWidth: '50px' }} /> 
            <h4>
              <span style={{ whiteSpace: 'nowrap' }}> {name}</span>
            </h4>
           
          </div>
          <hr className='mb-0' />
        </div>
        <MDBSideNavMenu>
          <MDBSideNavItem>
            <MDBSideNavLink icon='address-book' onClick={() => setBasicCollapse1(!basicCollapse1)}>
              Update Details
            </MDBSideNavLink>
            <MDBSideNavCollapse show={basicCollapse1}>
              <MDBSideNavLink>Mobile Number</MDBSideNavLink>
              <MDBSideNavLink>Email</MDBSideNavLink>
              <MDBSideNavLink>Work</MDBSideNavLink>
            </MDBSideNavCollapse>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='file' className='fas fa-fw me-3' />
              Reset Password
            </MDBSideNavLink>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='heart' className='fas fa-fw me-3' />
              Re-KYC verification
            </MDBSideNavLink>
          </MDBSideNavItem>
    
          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='trash' className='fas fa-fw me-3' />
                Delete/Deactivate
            </MDBSideNavLink>
          </MDBSideNavItem>
          
          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon icon='user-circle' className='fas fa-fw me-3' />
              Personal
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>
        <hr className='m-0' />
        <MDBSideNavMenu>
          <MDBSideNavItem>
          <MDBSideNavLink>
             <a onClick= {(e) => logout(e) }>
              <MDBIcon icon='user-astronaut' className='fas fa-fw me-3' />
              Logout
            </a>
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>     
      </MDBSideNav> */} 



      <div class="d-flex justify-content-center"
        style={{
          height: '100%',
          display: 'flex',
          padding:'100',
          justifyContent: 'center',
          alignItems: 'center'}}
      >
        <MDBCard>
        <span   className='square  border border-2 rounded-4'
                    >  
            <MDBCardBody >
              <form onSubmit={handleSubmit} style={{ display: !flag ? 'block' : 'none' }}>
                <MDBTabs pills justify className='mb-3 d-flex flex-row justify-content-between'>
                  <MDBTabsItem>
                    <MDBTabsLink id='loginTab' className='login--form--submit' 
                      onClick={() => handleJustifyClick('tab1')} 
                      active={justifyActive === 'tab1'} >
                      <h6>ABHA Card </h6>
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink id='regTab'
                      onClick={() => handleJustifyClick('tab2')} 
                      active={justifyActive === 'tab2'}>
                      <h6>Profile Details</h6>
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent>
                <MDBTabsPane show={justifyActive === 'tab1'}>
                  
                  <MDBRow class="d-flex justify-content-center">
                    {/* <MDBCol xl='4' md='4' className='mb-4'> */}
                        <MDBCard  class="d-flex justify-content-center">   
                          <div class="d-flex justify-content-center">       
                            <img id='pngCardImg' src={`data:image/png;base64,${pngCard}`} style={{width:450, height:220}}  /> 
                          </div>
                      </MDBCard>              
                    {/* </MDBCol> */}
                </MDBRow> 
              </MDBTabsPane>
                <MDBTabsPane show={justifyActive === 'tab2'}>
                    <MDBRow >
                      <MDBCol  xl='4' md='4' className='mb-4'>
                       <MDBCard class="d-flex justify-content-center" style={ {width : "25rem"} }>
                         <MDBContainer>
                          <div >
                             
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>FirstName</b> : {firstName} </span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>MiddleName</b> : {middleName} </span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>LastName</b> : {lastName} </span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>ABHA Address</b> : {healthId}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>ABHA ID </b>     : {healthIdNumber}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>Mobile Number</b>: {mobile}</span></p>
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>Email </b>       : {email}</span></p>
                              {/* <p><span><b>DateOfBirth</b> : {dob}</span></p> */}
                              <p><span><b style={{"color": "#000080" ,fontSize:'15px'}}>Address</b>   : {address}</span></p>
                           </div>
                          </MDBContainer>
                         

                     </MDBCard>
                     </MDBCol>
                     </MDBRow>
                  </MDBTabsPane>
                </MDBTabsContent>
              </form>
            </MDBCardBody>
          </span>
        </MDBCard>
        <br></br>
      </div>

     


      {/* <MDBRow>
        <MDBCol md='8'>
        <p className="float-end"><a href="#" onClick={toggleModal} ><b>View Details</b></a></p> 
        </MDBCol>
      </MDBRow> */}

  
         {/* <MDBModal show={modal} setShow={setModal} tabIndex='-1'>  
           <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                 Profile Details
              </MDBModalHeader>
              
               <MDBModalBody className="mx-3">
                 
                   <MDBRow>
                    <MDBCol>
                      <MDBInput 
                         className='mb-4'     
                          id="firstName" label="First Name" 
                          value={fullName}
                      />
                      </MDBCol>
                     </MDBRow>
                     <MDBRow>
                       <MDBCol>
                        <MDBInput
                          className='mb-4'   
                          id="dateofBirth" label="Date of Birth" 
                          value={dob} 
                        /> 
                      </MDBCol>
                    </MDBRow>
                   
                   <MDBRow>
                      <MDBCol>
                      <MDBInput 
                        className='mb-4'  
                         id="email" label="Email"  
                         value={email}
                      /> 
                      </MDBCol>

                   </MDBRow>
                    <MDBRow>
                       <MDBCol>
                        <MDBInput 
                          className='mb-4'  
                          id="gender" label="Gender"
                          value={gender}  
                        />
                      </MDBCol>
                    </MDBRow>
                     
                     <MDBRow>
                      <MDBCol>
                        <MDBInput  
                          className='mb-4' 
                            id="healthId" label="HealthID" 
                            value={healthId}
                        />
                       </MDBCol>
                     </MDBRow>

                     <MDBRow>
                      <MDBCol>
                      <MDBInput 
                       className='mb-4'  
                          id="healthIdNumber" label="HealthID Number" value={healthIdNumber}
                      />
                      </MDBCol>
                      </MDBRow>   
                       
                       <MDBRow>
                         <MDBCol>
                          <MDBInput  
                            className='mb-4' 
                            id="mobile" label="Mobile"  value={mobile}
                          /> 
                         </MDBCol>
                       </MDBRow>
                    
                     <MDBRow>
                       <MDBCol>
                       <MDBInput 
                        className='mb-4'  
                         id="statename" label="State Name"   value={state}
                      /> 
                       </MDBCol>
                     </MDBRow>
                     
                       
                       
                    <MDBBtn className="mb-4" color="info"
                         onClick= {toggleModal}>CLOSE </MDBBtn>
                   
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
        </MDBModal> */}
    
    </div>
  );
}
export default Card;
    