import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBContainer,
    MDBNavbarToggler,
    MDBAlert,
    MDBCardHeader,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody
} from 'mdb-react-ui-kit';
import Logo from '../assets/clogo.png';
import Nhalogo from '../assets/NHA.png';
import ablogo from '../assets/abhalogo.png';
import  Axios  from 'axios';
import {Buffer} from 'buffer';
  


const List = (props) => {

    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const [pngCard,setPNGCARD] = useState();
    const [showNav, setShowNav] = useState(false);
    const [showAlert, setShowAlert] = useState(false)
    const [alertId, setAlertId] = useState('')
    const [modal,setModal] = useState(false)


 
   useEffect(() => {
      async function fetchData() {
      var publicKey = await sessionStorage.getItem("ABDMPublicKey") ?? '';
      var accessToken = await sessionStorage.getItem("accessToken") ?? '';
      var encryptedOTP = await sessionStorage.getItem("encryptedOTP") ?? '';
      // console.log("otp:" ,encryptedOTP)
      var transactionId = await sessionStorage.getItem('transactionId') ?? '';
      
      const otpDataBody = {
        otp: encryptedOTP,
        txnId: transactionId,
        accessToken : accessToken
      };
  
      await Axios.post(`https://mpowerweb.clinally.com/verifyMobileOTPAPI`, otpDataBody)
      .then((otpResponse) => {
      if (otpResponse.status === 200) {
        if (otpResponse.data && otpResponse.data.mobileLinkedHid.length > 0) {
            // console.log("response : ", otpResponse.data.mobileLinkedHid);
            // console.log("response : ", otpResponse.data)
            setData(otpResponse.data.mobileLinkedHid);
                sessionStorage.setItem('txnId', otpResponse.data.txnId);
                sessionStorage.setItem('token', otpResponse.data.token);
                // console.log('token :', otpResponse.data.token)
                sessionStorage.setItem('mobileLinkedHid', JSON.stringify(otpResponse.data.mobileLinkedHid));
                // console.log("OTP VERIFIED")
          }
        else if(otpResponse.status === 422){
          //  alert('relogin again')
           setShowAlert(true);
        }
      }
      }).catch ((error) => {
        console.log(error);
        setShowAlert(true);
      })
      }
      fetchData();
    }, []); 


  async function  getProfileData(profileId)  {
      sessionStorage.setItem('profileId',profileId)
      navigate('/Card')
  }

    
return (
      <div  style={{ height: '100%' }} >
      <form>
    
        <MDBNavbar variant="light" dark expand='md' scrolling fixed='top' style={{ zIndex: 1,height:'8%', backgroundColor: "white" }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img src={Logo} style={{ height: '40px' }} alt="raledaa" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBNavbarBrand href='#'>
            <img src={Nhalogo} style={{ height: '40px' }} alt="NHALogo" />
          </MDBNavbarBrand>
          {/* <MDBNavbarBrand href='#'>
            <img src={ablogo} style={{ height: '120px' }} alt="AbhaLogo" />
          </MDBNavbarBrand> */}
        </MDBContainer>
      </MDBNavbar>
     

    <div  
      class=""
      // style={{
      //   position: 'absolute',
      //   bottom: '25%', paddingLeft:'580PX'}}
      
      style={{
        position: "fixed",bottom: "25%",left: "32%",
    }}
     >
     <div class="shadow-5-strong">
      <MDBRow>
        {data &&
            data.map((item, index) => (                 
              <MDBCol  md='6' className='mb-4'>  
                   
                  <MDBCard style={{width: "400px",height:"320px" }} color='unique-color'  className='classic-admin-card white-text' >
                  {/* <span class="border border-dark">         */}
                    <MDBCardBody className="p-3 my-5 d-flex flex-column" style={{ paddingLeft: '90px' }}>                             
                        <h5 className='white-text'><strong><center>ABHA DETAILS</center></strong></h5>
                         <br/>      
                          <h6>Health Id:  {item.healthId} </h6><br/>
                           <h6>Health Id Number:  {item.healthIdNumber}</h6> <br/>
                            <h5>Name: <strong>{item.name} </strong></h5>          
                             {/* <p><strong>Address: {item.phrAddress} </strong> </p> */}
                             <br />
                             <MDBBtn 
                               rounded className='mx-2'
                               style={{ backgroundColor: "#372e69" }}
                              onClick={(e) => getProfileData(index)}
                            >
                              Open
                            </MDBBtn>              
                        </MDBCardBody>         
                    {/* </span>  */}
                  </MDBCard>  
                  
              </MDBCol> 
            ))
         }
      </MDBRow>

   <MDBContainer>
      <MDBAlert show={showAlert} color='primary'  id={alertId}
         autohide
          width={800}
          position='top-right'
          offset={50}
          delay={6000}
          appendToBody>
        Session Expired! Please Relogin!
      </MDBAlert>
      </MDBContainer>
      </div>
   </div>
     
 </form>
    
     </div>
    );
}
export default List;
